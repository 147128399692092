import React, {useState, useContext} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import FormPeriodo from '../../components/FormPeriodo/FormPeriodo';
// import CustomTable from '../../components/CustomTable';
import DataTable from 'react-data-table-component';

function Vendas({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const [total, setTotal] = useState(0);
    const {dataIni, dataFim, getActiveProcard, token, refresh} = useContext(AppContext); 

    React.useEffect(() => {
      handleClick();
      setTitle('Compras');
    }, [refresh]);

    
    const handleClick = async () => {      
      
      setLoading(true);
      const procard = getActiveProcard();
      const dados = {
        id_procard: procard.id,
        dataIni: dataIni.toISOString().split('T')[0],
        dataFim: dataFim.toISOString().split('T')[0],
      }  


      const retorno = await fetchData.getVendasPeriodo(dados, token);  
      
      // //-- Sessão expirada
      if(retorno.code === 401){
        setLoading(false);
        sessionStorage.clear();    
        window.location.href = '/';      
      }

      setTableData(retorno.items)  
      
      let vtotal = 0;
      for (var i in retorno.items) {
        vtotal += parseFloat(retorno.items[i].total_final);
      }
      setTotal(vtotal);
      setLoading(false);
    }    

    const colunas = [
      {
          name: 'Qtde.',
          selector: row => row.qtde,
          sortable: true,
          width: '10%'
      },
      {
          name: 'Produto/Descrição',
          selector: row => row.descricao,
          sortable: true,
          width: '50%'
      },      
      {
        name: 'Valor',
        selector: row => row.preco,
        sortable: true,
        width: '10%'
    },      
    ];
    
    // const dados_tabela = [
    //     {
    //         id: 1,
    //         descricao: 'ATENOLOL 25MG 30CPRS',
    //         preco: '10.80',
    //         qtde: '3.00',
    //     },
    //     {
    //         id: 2,
    //         descricao: 'CLORIDRATO DE TRAZODONA 50MG CX 60 COMP',
    //         preco: '19.84',
    //         qtde: '5.00'
    //     },
    // ]          

    // const columns = [        
    //     {  name: 'DATA/HORA', selector: row => moment(row.data_hora_venda).format('DD/MM/YYYY HH:mm'), minWidth: '130px', sortable: true},        
    //     {  name: 'VALOR COMPRA', selector: row => (parseFloat(row.total_produtos)).toFixed(2), minWidth: '130px', sortable: true, center: true},
    //     {  name: 'DESCONTO', selector: row => (parseFloat(row.desconto)).toFixed(2), sortable: false, center: true, hide: 'md'},
    //     {  name: 'ACRÉSCIMO', selector: row => (parseFloat(row.acrescimo)).toFixed(2), sortable: false,hide: 'md'},
    //     {  name: 'TOTAL', selector: row => (parseFloat(row.total_final)).toFixed(2), sortable: true},
    //     {  name: '#', selector: row => row.id, sortable: true, hide: 'sm' },        
    // ];

    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                                
            <FormPeriodo handleClick={handleClick}/>  

            <DataTable
              columns={colunas}
              data={tableData}
              striped={true}
            />




            {/* <CustomTable 
              // title={`Compras no Período`}  
              // total={`(Total: R$ ${total.toFixed(2)})`}  
              columns={columns} 
              data={tableData} 
              //progressPending={progressPending}
            />                          */}
        </div>  
      </div>
  )
}

export default Vendas;