import React, {useState, useContext} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import FormPeriodo from '../../components/FormPeriodo/FormPeriodo';
// import CustomTable from '../../components/CustomTable';
import DataTable from 'react-data-table-component';
import Swal from 'sweetalert2'
import moment from 'moment';

function Produtos({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const [search, setSearch] = useState('ibupro');
    const {dataIni, dataFim, token} = useContext(AppContext); 

    React.useEffect(() => {
      //handleClick();
      setTitle('Consulta de Preços');
      setLoading(false);
    }, []);

    
    const handleClick = async () => {      

      if(search.length < 6) {
        Swal.fire({
          title: 'Oops!',
          html: 'Digite pelo menos 6 dígitos no campo de pesquisa!',
          icon: 'warning'     
      })
      return;
      }
      
      setLoading(true);
      // const procard = getActiveProcard();
      const dados = {
        search: search,
        dataIni: dataIni.toISOString().split('T')[0],
        dataFim: dataFim.toISOString().split('T')[0],
      }  


      const retorno = await fetchData.getProdutos(dados, token);  
      
      // //-- Sessão expirada
      if(retorno.code === 401){
        setLoading(false);
        sessionStorage.clear();    
        window.location.href = '/';      
      }

      setTableData(retorno.items)  
      
      let vtotal = 0;
      for (var i in retorno.items) {
        vtotal += parseFloat(retorno.items[i].total_final);
      }
      setLoading(false);
    }    


    const formatMoney = (value) => {      
      if (value) {
        value = Number(value);
        return `R$ ${value.toFixed(2)}`;
      } else {
        return "-";
      }
    };

    const colunas = [
      {
          name: 'Código',
          selector: row => row.codprod,
          sortable: true,
          width: '10%'
      },
      {
          name: 'Cód.Barras',
          selector: row => row.barras,
          sortable: true,
          width: '10%'
      },      
      {
        name: 'Nome Produto',
        selector: row => row.nomeprod,
        sortable: true,
        width: '60%'
      },      
      {
        name: 'Preço Venda',
        selector: row => row.precovenda,
        format: row => formatMoney(row.precovenda),         
        cell: row => <div>{formatMoney(row.precovenda)}</div>,      
        sortable: true,
        width: '20%'
      },      
    ];


    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                                
          <form>
            <div className="row mb-3">
              <div className="col-12 col-sm-3 pe-0">
                <label htmlFor="search" className="form-label">Nome do Medicamento (min. 6 digitos)</label>                            
                <input name="search" 
                    type="text" 
                    className="form-control" 
                    value={search}                     
                    onChange={(e) => setSearch(e.target.value)}                    
                    title="Pesquisar" 
                /> 
              </div>  
              <div className="col-12 col-sm-3">                                
                    <button type="button" className="mt-2 mt-md-4 btn btn-success" onClick={() => handleClick()}>Pesquisar</button>                                
                </div>                            
            </div>
          </form>              

          <DataTable
            columns={colunas}
            data={tableData}
            striped={true}
          />
        </div>  
      </div>
  )
}

export default Produtos;