import React, { useState, useContext } from 'react';
// import fetchData from '../../global/FetchData';
import './Boletos.scss';
import { AppContext } from '../../global/context'
// import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
// import { Doughnut } from 'react-chartjs-2';
// import {CircleFill} from 'react-bootstrap-icons';

// import logo from '../../assets/images/logo-prodabit-transp.png'

function Boletos({setLoading, setTitle}) {

  // const [chartData, setChartData] = useState(null);
  // const [tableData, setTableData] = useState([]);    
  // const {token, getActiveProcard, atualizar} = useContext(AppContext);    

  React.useEffect(() => {
    
    //-- Adiciona Script de Segurança MPago dentro do body
    // const script = document.createElement('script');
    // script.src = 'https://www.mercadopago.com/v2/security.js';
    // script.setAttribute("view", "home");
    // document.body.appendChild(script);        
    const loadAll = async () => {
      
      setTitle('Boletos');      
    }
    loadAll();

    const timeout = setTimeout(() => {
      setLoading(false);
    }, 400);
    return () => clearTimeout(timeout);

  }, [])

  

  return (
    <div className="home--container">
        <h1>Boletos</h1>
    </div>
  )
}


export default Boletos;