import React, {useState, useContext} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import DataTable from 'react-data-table-component';
import  helper  from '../../global/Helper' 
// import Swal from 'sweetalert2'

function Controlados({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const [empresa] = useState(0);
    const {token} = useContext(AppContext); 


    React.useEffect(() => {      
    
      setTitle('Receitas Pendentes');      

      const loadData = async () => {
        setLoading(true);        
        
        
        const dados = {
          empresa_id: empresa,
        }  
        
        const retorno = await fetchData.getControlados(dados, token);  
        console.log(retorno);
        
        //-- Sessão expirada
        if(retorno.code === 401){
          setLoading(false);
          sessionStorage.clear();    
          window.location.href = '/';      
        }
  
        setTableData(retorno.items)
        setLoading(false);
      }; 
      loadData();         
    }, [setLoading, setTitle, token, empresa]);      

    
    // p.NOMECOMPLETO, cli.NOMECLI, v.DATA, v.QUANTPROD,  conv.CODEMP as CODEMP, emp.nomeemp
    const colunas = [
      {
        name: 'Data',
        selector: row => helper.MySQLToDate(row.DATA),             
        sortable: true,
        width: '10%'
      },        
      {
        name: 'Empresa',
        selector: row => row.NOMEEMP,
        sortable: true,
        width: '20%'
      },  
      {
          name: 'Cliente',
          selector: row => row.NOMECLI,
          sortable: true,
          width: '30%'
      },
      {
          name: 'Medicamento',
          selector: row => row.NOMECOMPLETO,
          sortable: true,
          width: '30%'
      },      
      {
        name: 'Quantidade',
        selector: row => row.QUANTPROD,
        sortable: true,
        width: '10%'
      },  
    ];

    const paginationComponentOptions = {
      rowsPerPageText: 'Registros por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',      
  };


    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">                                
          <DataTable
            title="Receitas Pendentes"
            columns={colunas}
            data={tableData}
            striped={true}
            defaultSortFieldId={1}            
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[14,30,50,100]}             
            paginationPerPage={14}            
            // selectableRows
          />
        </div>  
      </div>
  )
}

export default Controlados;