import axios from 'axios';

//const API_BASE = 'http://localhost/pagamentos/server/public';
// const API_BASE = 'https://natusnutri-maxi.procardbr.com.br/public';

const API_BASE = ((window.location.href).indexOf('localhost:3000') > 0) ? 'http://localhost/financeiropro/server/public' : 'https://' + window.location.hostname + '/public';
// console.log(API_BASE);
axios.defaults.timeout = 50000;

const basicFetch = async (endpoint, data, token, method = "POST") => {

    //const token = getToken();
    // axios.defaults.headers.common = {}
    // axios.defaults.headers.post['Access-Control-Allow-Origin'] = 'http://localhost:3000/';     

    try{
        const res = await axios({
        method: method,
        url: `${API_BASE}${endpoint}`, 
        data: data,
        headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json;charset=UTF-8", 
            //   "Access-Control-Allow-Origin": "*" 
        }      
        })
        const retorno = {
            items: res.data,
            status: "success",   
            message: res.data.message ? res.data.message : "success",
            code: (res.status) ? res.status : 200           
        }
        //console.log('retorno: ' + JSON.stringify(retorno));
        return retorno;        
    }
    catch (error) {
        const erro = {
            items: [],
            status: "error",               
            message: error.response.data.message ? error.response.data.message : error.message,            
            code: 404
        }          
        //console.log('erro: ' + JSON.stringify(erro));
        return (erro);        
    }    
};

const basicGet = async (endpoint, data, token) => {

    try {
        const res = await axios({
            method: 'GET',
            url: `${API_BASE}${endpoint}`, 
            params: data,
            headers: {
                "Authorization": `Bearer ${token}`,
                "Content-Type": "application/json;charset=UTF-8",         
            }      
        })
        const retorno = {
            items: res.data,
            status: "success",   
            messsage: "success",
            code: 200           
        }
        return retorno;    
    }
    catch (error) {
        const erro = {
            items: [],
            status: "error",   
            messsage: error.message,
            code: error.response.status           
        }          
        return (erro);        
    }    
};

const makeLogin  = async (data) => {
    const resp = await basicFetch('/auth/login', data);        
    return resp.items;
}

const changePassword = async (data, token) => {
    return await basicFetch(`/api/change_password`, data, token)    
}

const reset_password  = async (data) => {
    const resp = await basicFetch('/auth/reset_password', data);        
    return resp;
}

const getConfig = async (token) => {
    
    let retorno = await basicGet(`/api/configuracoes`, [], token);
    return {
        slug: 'configuracoes',
        title: 'Configurações',
        code: retorno.code,
        items: retorno.items
    };
}

const saveConfig = async (data, token) => {
    const retorno = await basicFetch(`/api/configuracoes`, data, token)
    return {        
        code: retorno.code,        
        data: retorno.items
    }    
}

//-- Obtém os itens de configuração antes de ter o token
//-- Ou seja, configurações antes de fazer o login
const getConfig2  = async () => {
    const res = await axios({
        method: 'GET',
        url: `${API_BASE}/api/configuracoes2`,         
        headers: {"Content-Type": "application/json;charset=UTF-8"}      
      })
      return (res.data);
};

const getControlados = async (data, token) => {    
    const retorno = await basicFetch(`/api/controlados/listar_pendentes`, data, token)                 
    return {
        slug: 'pendentes',
        title: 'pendentes',
        code: retorno.code,
        items: retorno.items 
    };
}

const getFaturas = async (data, token) => {    
    const retorno = await basicFetch(`/api/faturas/listar`, data, token)   
    return {
        slug: 'faturas',
        title: 'faturas',
        code: retorno.code,
        items: retorno.items 
    };
}

const salvarFatura = async (data, token) => {
    const retorno = await basicFetch(`/api/faturas/salvar`, data, token)
    return retorno;
}

const getEmpresas = async (data, token) => {    
    const retorno = await basicFetch(`/api/empresas_ativas`, data, token)   
    return {
        slug: 'empresas',
        title: 'empresas',
        code: retorno.code,
        items: retorno.items 
    };
}

const getFatura = async (id, token) => {    
    const retorno = await basicFetch(`/api/faturas/getFatura`, id, token)                 
    return {
        slug: 'fatura',
        title: 'fatura',
        code: retorno.code,
        items: retorno.items 
    };
}

const cancelFatura = async(id, token) => {
    const retorno = await basicFetch(`/api/faturas/cancelFatura`, id, token)                 
    return {
        slug: 'fatura',
        title: 'fatura',
        status: retorno.items.status,
        mensagem: retorno.items.mensagem 
    };
}


function printForm(formId, url, onSubmitForm, fatura_id, tipo) { 
    return(
      <form onSubmit={onSubmitForm}
        id={formId}
        action={`${API_BASE}/${url}`} 
        method="post" 
        target="_blank">
        <input type="hidden" name='id' value={fatura_id} />
        <input type="hidden" name='tipo' value={tipo} />
      </form>
    )
}


const printFatura = async (id, token) => {    
    const retorno = await basicGet(`/api/faturas/printFatura`, id, token)                 
    return {
        slug: 'fatura',
        title: 'fatura',
        code: retorno.code,
        items: retorno.items 
    };
}

const getUsuarios = async (data, token) => {    
    const retorno = await basicFetch(`/api/usuarios/listar`, data, token)                 
    return {
        slug: 'usuarios',
        title: 'usuarios',
        code: retorno.code,
        items: retorno.items 
    };
}


const getProdutos = async (data, token) => {    
    const retorno = await basicFetch(`/api/produtos/search`, data, token)                 
    return {
        slug: 'produtos',
        title: 'produtos',
        code: retorno.code,
        items: retorno.items 
    };
}




const fetchData = {
    basicFetch,
    makeLogin, getConfig, getConfig2, saveConfig, getControlados, getFatura, salvarFatura, getEmpresas, getProdutos, cancelFatura, printFatura, printForm,
    getFaturas, getUsuarios, changePassword, reset_password
};

export default fetchData


/*
.then(function (res) {
        return (res.data);
    })
    .catch(function (error) {
        if (error.response) {
        //   console.log(error.response.data);
        //   console.log(error.response.status);
        //   console.log(error.response.headers);
          
          //-- sessao expirada somente se token foi enviado
          if(token && error.response.status === 401){
            sessionStorage.clear();
            window.location.pathname = '/';
          } 

          //-- outros erros: retorna o erro completo
          const erro = {
              status: error.response.status,   
              messsage: error.response.data,
              statusText: error.response.statusText           
          }          
          return (erro);
        }
    });  

.then(res => {
        return (res.data);
    })
    .catch(error => {
        if (error.response) {
          //-- sessao expirada somente se token foi enviado
          if(token && error.response.status === 401){
            sessionStorage.clear();
            window.location.pathname = '/';
          } 
        }        
        //-- outros erros: retorna o erro completo
        const erro = {
            items: [],
            status: "error",   
            messsage: error.message,
            code: error.code           
        }          
        return (erro);
    });       
*/

