import React, {useState, useContext, useMemo, useCallback} from 'react';
import { AppContext } from '../../global/context' 
import fetchData from '../../global/FetchData';
import DataTable from 'react-data-table-component';
import TableHeader from '../../components/TableHeader/TableHeader';
import moment from 'moment';
import { PersonLinesFill , JournalText, XCircle, PencilSquare } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import "./Faturas.scss";
import EditFatura from './EditFatura';
// import Swal from 'sweetalert2'

const FilterComponent = ({ filterText, onFilter, onClear }) => (
  <><input 
        type="text" 
        value={filterText} 
        onChange={onFilter}
        placeholder="Filtrar"
        aria-label="Search Input"
        className="search-input"           
        id="input-text-filter"
      />
      <button 
        type="button" 
        className="search-input-button"
        onClick={onClear}>X            
      </button></>
);

function Faturas({setLoading, setTitle}) {    

    const [tableData, setTableData] = useState([]);
    const {token, refresh, setRefresh} = useContext(AppContext); 
    const [filterText, setFilterText] = useState('');
	  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
    const MySwal = withReactContent(Swal); 
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal  = () => setShowModal(true);
    const [empresas, setEmpresas] = useState([])

    let startdate = moment();
    startdate = startdate.subtract(1, "M");
    startdate = startdate.format("YYYY-MM-DD");

    const [fatura, setFatura] = useState({
      id: 0,
      empresa_id: 0,
      inicio: startdate,
      fim: moment().format('YYYY-MM-DD'),
      vencimento: moment().format('YYYY-MM-DD')
    });
    
    //-- Seta o título 
    React.useEffect(() => { 
      setTitle('Faturas');      
    },[setTitle])     
    
    
    const loadFaturas = useCallback(async () => {
      setLoading(true);        
      const dados = {empresa_id: 0}  
      const retorno = await fetchData.getFaturas(dados, token);  
      
      //-- Sessão expirada
      if(retorno.code === 401){
        setLoading(false);
        window.location.href = '/';      
      }
      setTableData(retorno.items)
      setLoading(false);
    },[setLoading, token])
    
    //-- Carrega as ultimas faturas
    React.useEffect(() => {   
      loadFaturas();         
    }, [loadFaturas, refresh]);    
    

    //-- Carrega lista de empresa para utilizar no formulário de edição
    React.useEffect(() => {      
      const loadData = async () => {
        setLoading(true);                
        const retorno = await fetchData.getEmpresas({}, token);  
        setEmpresas(retorno.items);
        //console.log(empresas);
        setLoading(false);
      }; 
      loadData();         
    }, [setLoading, token]);   

    
    const filteredItems = tableData.filter(
      item => item && (item.nomeemp.toLowerCase().includes(filterText.toLowerCase()) || 
                      (item.status.toLowerCase().includes(filterText.toLowerCase()))
      )      
    );


    const handleEditButtonClick = async (state) => {
      
      if(!state.target.id || state.target.id <= 0) return;

      setLoading(true);                
      const retorno = await fetchData.getFatura(state.target.id, token);  
      setFatura(retorno.items)
      console.log(retorno.items);
      handleShowModal()
      setLoading(false);
    };

    
    const handleCancelClick = (state) => {
      MySwal.fire({
        title: "Confirma a exclusão do item " +state.target.id+ "?",
        text: "Uma vez exluído, não será possível reativar!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, Excluir!',
        cancelButtonText: 'Não!',        
        dangerMode: true,
      })
      .then(async(result) => {  
        if(result.value){
          const retorno = await fetchData.cancelFatura(state.target.id, token);
          if (retorno.status === "error") {
              MySwal.fire({
                  title: "Oops!",
                  html: retorno.data.message,
                  icon: "error",
              });
              return;
          } 
          setRefresh(!refresh) 
          //loadFaturas();            
        }
      })  
    }

    const handleNovoClick = async () => {      
      //setState({...state, 'lst_empresas': empresas});    
      // let startdate = moment();
      // startdate = startdate.subtract(1, "M");
      // startdate = startdate.format("YYYY-MM-DD");

      setFatura({
        id: 0,
        empresa_id: 0,
        inicio: startdate,
        fim: moment().format('YYYY-MM-DD'),
        vencimento: moment().format('YYYY-MM-DD')
      });
      handleShowModal()
    };

    const onSubmitFaturaCasa = (event) => {
      //event.preventDefault();
      console.log('casa')
    }

    const onSubmitFaturaFamilia = (event) => {
      //event.preventDefault();
      console.log('familia')
    }

    const onSubmitSaveFatura = async (evt) => {
      evt.preventDefault();
      
      if(!fatura.id_empresa || fatura.id_empresa <= 0){
        MySwal.fire({title: "Oops!", html: 'Selecione a empresa.', icon: "warning"}); 
        setLoading(false);  
        return;
      }
      
      const retorno = await fetchData.salvarFatura(fatura, token);
      handleCloseModal();
      if (retorno.status === "success") {            
        loadFaturas();
        MySwal.fire({title: "Tudo certo!", html: retorno.message, icon: "success"}
        )                          
      }
      else{        
        MySwal.fire({title: "Oops!", html: retorno.message, icon: "error"});
      }
      setLoading(false);   
      //console.log(JSON.stringify(fatura))
      //Form submission happens here
    }

    //-- change dos campos do formulário de edição EditFatura
    const onFormChange = (evt) => {
        const value = evt.target.value;
        setFatura({...fatura, [evt.target.name]: value});    
    }
   
    const subHeaderComponentMemo = useMemo(() => {
      const handleClear = () => {  
        if (filterText) {  
          setResetPaginationToggle(!resetPaginationToggle);  
          setFilterText('');  
        }  
      };
      return (  
        <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
      );
    }, [filterText, resetPaginationToggle]);


    // p.NOMECOMPLETO, cli.NOMECLI, v.DATA, v.QUANTPROD,  conv.CODEMP as CODEMP, emp.nomeemp
    const conditionalRowStyles = [
      {
        when: row => row.status === 'CANCELADO',
        style: {
          textDecoration: 'line-through',
          color: '#d263a2'        
        },
      },
    ];
    
    const colunas = [
      {
        name: 'ID',
        selector: row => row.id,        
        sortable: true,
        width: '6%'        
      },        
      {
        name: 'Data Fechamento',
        //selector: row => row.data,   
        selector: row => moment(row.data).format('DD/MM/YYYY'),
        sortable: true,
        width: '14%'
        // {  name: 'DATA/HORA', selector: row => moment(row.data_hora_venda).format('DD/MM/YYYY HH:mm'), sortable: true, minWidth: '130px'},
      },    
      {
        name: 'Casa',
        selector: row => row.nomeemp,   
        sortable: true,
        width: '38%'
        //--  (row) => ({ backgroundColor: row.isSpecial ? 'pink' : inherit })
      },    
      {
        name: 'Valor com Descontos',
        selector: row => ('R$ ' + (parseFloat(row.valor)).toFixed(2)),   
        sortable: true,
        width: '12%'
        // {  name: 'VLR. UNIT.', selector: row => (parseFloat(row.valor_unitario)).toFixed(2), sortable: true, hide: 'sm'},
      },    
      {
        name: 'Status',
        selector: row => row.status,   
        sortable: true,
        width: '10%'
      },    
      {
        name: 'Ações',        
        cell:(row) => (
          <>
          <PencilSquare 
            className="icon-table" 
            color="orange" 
            size={15} 
            onClick={handleEditButtonClick} 
            id={row.id} 
            title="Boletos">
          </PencilSquare>        
          <button
            className="icon-table"             
            title="Fatura Casa"
            type="submit" 
            form="printFaturaCasa"
            // value={row.id}
            onClick={() => setFatura(row.id)}>            
            <JournalText color="royalblue" size={15} ></JournalText>
          </button>   
          <button
            className="icon-table"             
            title="Fatura Família"
            type="submit" 
            form="printFaturaFamilia"
            //value="Submit"
            onClick={() => setFatura(row.id)}>            
            <PersonLinesFill color="royalblue" size={15} ></PersonLinesFill>
          </button>   
          <XCircle 
            className="icon-table" 
            color="red" 
            size={15} 
            onClick={handleCancelClick} 
            id={row.id} 
            title="Cancelar Fatura">
          </XCircle>        
          </>
        ),
        // ignoreRowClick: true,
        // allowOverflow: true,
        // button: true,                
        width: '20%'
      },    
    ];

    const paginationComponentOptions = {
      rowsPerPageText: 'Registros por página',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',      
  };


    return(           
      <div className="container-fluid">   
        <div className="m-0 m-sm-4">    
          <EditFatura show={showModal} fatura={fatura} empresas={empresas} handleClose={handleCloseModal} handleChange={onFormChange} onSubmitHandler={onSubmitSaveFatura}/>                            
          <DataTable
            title={TableHeader('Últimas Faturas', true, handleNovoClick, false, '')}
            //title="Últimas Faturas"
            columns={colunas}
            striped={true}
            conditionalRowStyles={conditionalRowStyles}
            // defaultSortFieldId={0}            
            pagination
            paginationComponentOptions={paginationComponentOptions}
            paginationRowsPerPageOptions={[14,30,50,100]}             
            paginationPerPage={14}            
            data={filteredItems}
            paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
            subHeader
            subHeaderComponent={subHeaderComponentMemo}
            //selectableRows
            persistTableHead                        
          />
        </div>  
        {fetchData.printForm('printFaturaCasa', 'api/faturas/printFatura', onSubmitFaturaCasa, fatura, 'casa')}
        {fetchData.printForm('printFaturaFamilia', 'api/faturas/printFatura', onSubmitFaturaFamilia, fatura, 'familia')}
      </div>
  )
}

export default Faturas;