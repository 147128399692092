import React, {createContext, useState} from 'react'

const AppContext = createContext();

const AppProvider = ({children}) => {

    var dt = new Date(); 
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [dataIni, setDataIni] = useState(startOfMonth(dt));
    const [dataFim, setDataFim] = useState(dt);
    const [refresh, setRefresh] = useState(false)    
    //const [procards, setProcards] = useState([]);    
    //const [procard, setProcard] = useState();    

    React.useEffect(() => {
      //getProcards();
    }, []) 
    
    
    function getProcards(){     
      const procardsString = sessionStorage.getItem('procards');      
      let procard_lst = JSON.parse(procardsString);
      procard_lst = procard_lst ? procard_lst  : undefined;  //-- tem que colocar desta forma para não dar erro na primeira renderização    
      //setProcards(procard_lst);
      return(procard_lst);
    }
    

    async function saveProcards(list) {
      sessionStorage.setItem('procards', JSON.stringify(list));      
      //setProcards(list);
    };    
    

    async function setActiveProcard(numero){      
      
      sessionStorage.setItem('activeProcard', numero);   
      const list = getProcards();
      const item = list.find(x => x.numero === numero);   
      return item;     
      //console.log('procard', procard)
    }   


    function getActiveProcard(){
       const numero = sessionStorage.getItem('activeProcard');      
       if(!numero || numero === '') return undefined;
       const list = getProcards();
       const item = list.find(x => x.numero === numero);   
       return item;
    }


    function getToken(){      
      const tokenString = sessionStorage.getItem('token');      
      const token = JSON.parse(tokenString);
      return (token) ? token : undefined;  //-- tem que colocar desta forma para não dar erro na primeira renderização    
    }
    const [token, setToken] = useState(getToken());

    function saveToken(token) {
      sessionStorage.setItem('token', JSON.stringify(token));      
      setToken(token);
    };    



    const openCloseSidebar = () => { setIsSidebarOpen(!isSidebarOpen); }
    const data = {
      dataIni, setDataIni, dataFim, setDataFim, 
      isSidebarOpen, openCloseSidebar,
      getProcards, saveProcards, getActiveProcard, setActiveProcard,
      token, setToken: saveToken,
      refresh, setRefresh
    }


    function startOfMonth(date){
      // return new Date(date.getFullYear() -1, date.getMonth(), 1);
      return new Date(date.getFullYear(), date.getMonth(), 1);
    }  


    return(
      <AppContext.Provider value={ data }>
         {children}
      </AppContext.Provider>
    )
}

export {AppContext, AppProvider}