import React from 'react';
import "./EditFatura.scss";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
// import Swal from 'sweetalert2'


function EditFatura({show, fatura, empresas, handleClose, handleChange, onSubmitHandler}){

    return (
    <>
        <Modal show={show} onHide={handleClose} centered={true} >
        <Modal.Header closeButton>
            <Modal.Title>{(fatura.empresa_id > 0) ? fatura.nomeemp : 'Novo Registro'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form id="formEditFatura" onSubmit={onSubmitHandler}>
                <Form.Group className="mb-3" controlId="dtVencimento">
                    <Form.Group className="mb-3" controlId="dtInicio">
                        <Form.Label>Casa:</Form.Label>
                        <Form.Select name="id_empresa" value={fatura.empresa_id} aria-label="" className="form-select form-select-sm" onChange={handleChange}>
                            <option>Selecione a Casa/ILPI</option>
                            {empresas && 
                                empresas.map((item) => {
                                    return <option key={item.codemp} value={item.codemp}>{item.nomeemp}</option>
                                })                            
                            }                           
                        </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dtInicio">
                        <Form.Label>Data Início:</Form.Label>
                        <Form.Control 
                            name="dt_inicio"
                            type="date"
                            className="form-control form-control-sm"                        
                            //placeholder={data.dt_inicio}
                            onChange={handleChange}
                            value={fatura.inicio}                            
                        />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dtFim">
                        <Form.Label>Data Fim:</Form.Label>
                        <Form.Control 
                            name="dt_fim"
                            type="date"
                            className="form-control form-control-sm"                        
                            //placeholder={fatura.fim}
                            onChange={handleChange}
                            value={fatura.fim}
                        />
                    </Form.Group>
                    <Form.Label>Vencimento</Form.Label>
                    <Form.Control
                        name="dt_vencimento"
                        type="date"
                        className="form-control form-control-sm"
                        //placeholder={fatura.vencimento}
                        onChange={handleChange}
                        value={fatura.vencimento}
                        autoFocus                    
                    />
                </Form.Group>
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
            Fechar
            </Button>
            <Button type="submit" form="formEditFatura" variant="success">
            Salvar Registro
            </Button>
        </Modal.Footer>
        </Modal>
    </>
    );
}


export default EditFatura;