import React, {useContext, useState, useRef} from 'react';
//import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
// import fetchData from './global/FetchData';
import { AppContext } from './global/context' 
// import Home from './pages/Home/Home'
import Home from './pages/Home/Home'
import Faturas from './pages/Faturas/Faturas'
import Usuarios from './pages/Usuarios/Usuarios'
import Boletos from './pages/Boletos/Boletos'
import Vendas from './pages/Vendas/Vendas'
import Login from './pages/Login/Login'
// import Config from './pages/Config/Config'
import Controlados from './pages/Controlados/Controlados'
import Produtos from './pages/Produtos/Produtos'
// import Sucesso from './pages/Pagamentos/Sucesso'
// import SucessoCielo from './pages/Pagamentos/SucessoCielo'
// import Erro from './pages/Pagamentos/Erro'
// import Pending from './pages/Pagamentos/Pending'
import './App.css';

import SideBar from './components/SideBar/SideBar';
import TopBar from './components/TopBar/TopBar';
// import Footer from './components/Footer/Footer';
import Loading from './global/Loading';
// import AlterarSenha from './pages/Login/AlterarSenha';
import EsqueceuSenha from './pages/Login/EsqueceuSenha';


// import { Camera } from 'react-feather';
//import { ArrowRight } from 'react-bootstrap-icons';

function App() {  

  const [loading, setLoading] = useState(true);
  // const [config, setConfig] = useState({});
  const [procard, setProcard] = useState();
  const [pageLoaded, setPageLoaded] = useState(false);
  const [title, setTitle] = useState('Resumo do Consumo Procard :: Dashboard');  
  const deviceId = useRef() 
  const {getActiveProcard} = useContext(AppContext);   
 
  React.useEffect(() => {    
    const loadAll = async () => {
      // const res = await fetchData.getConfig2(); 
      // if(res) setConfig(res);      
      
      const active = getActiveProcard()
      setProcard(active)      
      setPageLoaded(true);
    }

    loadAll();
  }, [getActiveProcard])  


  //-- Se página já foi carregada. Já passou pelo UseEffect()  
  if(pageLoaded && !procard) {                
    return(
      <Router basename="/">
        <Routes>              
          <Route exact path="/" element={<Login setLoading={setLoading} />}></Route>
          <Route exact path="/resetar_senha" element={<EsqueceuSenha setLoading={setLoading} setTitle={setTitle} />}></Route>
        </Routes>                        
      </Router>    
    ) 
  }  

  
 
  return (      
      <Router basename="/">
        {procard && 
        <div className="d-flex" id="wrapper">
            {procard.first_access === '0' && <SideBar /> }           
            <div id="page-content-wrapper">
              <input type="hidden" id="deviceId" ref={deviceId}/>
              <TopBar title={title}/>
              <Routes>              
                {/* <Route exact path="/logout" render= {() => console.log('Heading to /')} element={<Login setToken={setToken}/>}></Route>  */}
                <Route exact path="/faturas" element={<Faturas setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/boletos" element={<Boletos setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/vendas" element={<Vendas setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/logout" element={<Login />}></Route>
                <Route exact path="/receitas_pendentes" element={<Controlados setLoading={setLoading} setTitle={setTitle}/>}></Route>                
                <Route exact path="/usuarios" element={<Usuarios setLoading={setLoading} setTitle={setTitle}/>}></Route>
                <Route exact path="/lista_precos" element={<Produtos setLoading={setLoading} setTitle={setTitle}/>}></Route>
                
                <Route exact path="/" element={<Home setLoading={setLoading} setTitle={setTitle} deviceId={deviceId}/>}></Route>
                <Route path="*" component={() => "404 - Página não encontrada!"} />                                         
              </Routes>                      
              {/* <Footer /> */}
            </div>          
        </div>
        }
        {loading && <div className="overlay"><Loading /></div>}
      </Router> 
  );
}

export default App;

