import React, {useContext, useState} from 'react';
import { NavLink } from 'react-router-dom';
//import fetchData from "../../global/FetchData";
import './SideBar.scss'
// import logo from '../../assets/images/favicon.ico'
import { AppContext } from '../../global/context' 


function SideBar() { 
    
  //-- recebo os parametros do context    
  const {isSidebarOpen, openCloseSidebar, getActiveProcard, token} = useContext(AppContext); 
  const [procard, setProcard] = useState();
  const { innerWidth: width} = window;
  const name_class = isSidebarOpen ? 'bg-light border-right sidebar-wrapper' : 'bg-light border-right sidebar-wrapper open';
  const [config] = React.useState({});  
    
  const handleLogout = () => {
    sessionStorage.clear();
    //window.location.pathname = '/#logout';        
    //window.location.href = '/public/';        
    window.location.href = '/';        
    //sessionStorage.removeItem("token");        
  }

  React.useEffect(() => {
    
    //-- Fecha a sidebar no primeiro carregamento
    if(width < 700) openCloseSidebar();
    const loadData = async () => {        
        // const retorno = await fetchData.getConfig2(token);                
        
        // //-- Sessão expirada
        // if(retorno.code === 401){            
        //     sessionStorage.clear();    
        //     window.location.href = '/';      
        // }        
        // setConfig(retorno) 
        
        const active = getActiveProcard()
        setProcard(active)      
    };   
    loadData();   
  }, [width, token, openCloseSidebar, getActiveProcard]);

  const handleClick = () => {
      if(width < 700) openCloseSidebar()      
  }
  
  return(
    <div className={name_class} >
        <div className="top-sidebar-heading">
            <span className="navbar-brand mb-2" href="#">
                <img src='/assets/images/logo-white.png' width="26" height="26" alt=""/>                
            </span>  
            <span className="sidebar-head-title">Finance Pro</span>
        </div>

        <div className="list-group list-group-flush" id="menu-lateral">
        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>.: PRINCIPAL .:</span>
        </h6>
        
        <ul className="nav flex-column mb-2">    
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/'><i className="bi-house" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Home</NavLink>
            </li>            
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/faturas'><i className="bi-stickies" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Faturas</NavLink>
            </li>            
            {/* <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/boletos'><i className="bi-receipt-cutoff" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Gerar Boleto de Pgto.</NavLink>
            </li>               */}
            {/* <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/vendas'><i className="bi-bag" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Compras</NavLink>
            </li> */}
            <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/receitas_pendentes'><i className="bi-shield-lock" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Receitas Pendentes</NavLink>
            </li>            
        </ul>

        <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
        <span>.: USUÁRIOS .:</span>
        </h6>
        <ul className="nav flex-column mb-2">
            <li className="nav-item">            
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/usuarios'><i className="bi-person" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Usuários</NavLink>
            </li>
            {/* <li className="nav-item">
                <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/add-creditos'><i className="bi-credit-card" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Realizar Recarga</NavLink>
            </li>  */}
        </ul>

        {/* título do cardápio */}
        {(config.show_cardapio === "1" || config.link_cardapio !== "") && 
        <>
            <h6 className="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
            <span>.: PRODUTOS/PREÇOS .:</span>
            </h6>
            <ul className="nav flex-column mb-2">
                <li className="nav-item">
                    <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/lista_precos'><i className="bi-list-columns" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Consulta de preços</NavLink>
                </li>              
            </ul>            
        </>
        }


        <ul className="nav flex-column mb-2">                
            
            {procard && procard.tipo === "SUR" &&
                <li className="nav-item">
                    <NavLink onClick={() => handleClick()} className={({isActive}) => isActive? "nav-link active": 'nav-link'} to='/configuracao'><i className="bi-gear" style={{fontSize: "1.2rem", marginRight:"5px"}}></i> Configurações</NavLink>
                </li>  
            }


            <li className="nav-item">
                <NavLink 
                    className='nav-link' 
                    onClick={() => handleLogout()}
                    to='/logout'>
                    <i className="bi-door-open" style={{fontSize: "1.2rem", marginRight:"5px", color: "red"}}></i>                     
                    Sair
                </NavLink>
            </li>            
        </ul>              
        </div>    
    </div>
  )
}

export default SideBar;



