import React from 'react';
import "./Home.scss";

function Home({setLoading, setTitle}) {    

    //const {token, refresh} = useContext(AppContext); 

    React.useEffect(() => {
      //handleClick();
      setTitle('Droganet Finance Pro');
      setLoading(false);
    }, [setLoading, setTitle]);


    return(           
      <div className="container-fluid">   
        <div id="bkg_home">                                
          <img src={`/assets/images/bkg_home_droganet.png`} alt="background"/>                      
        </div>  
      </div>
  )
}

export default Home;