import React, { useState, useContext } from "react";
//import { NavLink } from 'react-router-dom';
import Form from "react-bootstrap/Form";
import fetchData from '../../global/FetchData';
import { AppContext } from '../../global/context' 
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'
import { Facebook, Youtube, Twitter, Instagram } from "react-feather";
import "./Login.scss";
// import logo_prodabit from '../../assets/images/logo-prodabit-transp.png'
// import bgImage from '../../assets/images/bakground-procard-propdv3.png'

export default function Login({setLoading}) {
  
  const [login, setLogin] = useState({"background_color": 'fafbfc', "card_color": "#eaeaea", "font_color": "#747577"});
  const [senha, setSenha] = useState("");   
  const [estilo, setEstilo] = useState({});   
  const {saveProcards, setActiveProcard} = useContext(AppContext); 
  const {setToken} = useContext(AppContext); 
  const MySwal = withReactContent(Swal);

  React.useEffect(() => {
    const loadAll = async () => {        
        const res = await fetchData.getConfig2(); 
        if(res) setEstilo(res);      
    }
    loadAll();    
  }, []) 
  

  function validateForm() {
    return login.length > 0 && senha.length > 0;
  }

  async function handleSubmit(event) {
    
    event.preventDefault();    
    setLoading(true);
    const data = await fetchData.makeLogin({
        login: login,
        senha: senha,
    });         
    
    if(data.status !== 200){
        MySwal.fire({
            title: 'Oops!',
            html: 'Não foi possível fazer login. Verifique o número e a senha e tente novamente!',
            icon: 'warning'     
        })
        return;
    }
    
    await saveProcards(data.procards);
    await setActiveProcard(data.procards[0].numero);
    setToken(data.access_token);    
    setLoading(false);
    //window.location.href = '/public/';      
    window.location.href = '/';      
    // window.location.href = data.home_page;        
  }  

//   const esqueceuSenhaHandler = () => {
//     window.location.href = '/resetar_senha';                
//   }

  let background_ = {"backgroundColor": estilo.background_color}
  if(estilo.background_img && estilo.background_img !== ""){
    background_ = {...background_, "backgroundSize": "cover",
                                   "backgroundRepeat": "no-repeat",
                                   "backgroundAttachment": "fixed",
                                   "backgroundImage": `url(/assets/images/${estilo.background_img})`}
  }                     
    
  return (

    // <!-- Section: Design Block -->
    <section className="background-radial-gradient overflow-hidden">
    <div className="container px-4 py-5 px-md-5 text-center text-lg-start my-5">
        <div className="row gx-lg-5 align-items-center mb-5">
        <div className="col-lg-6 mb-5 mb-lg-0" style={{zIndex: "10"}}>
            <h1 className="my-5 display-5 fw-bold ls-tight" style={{color: "hsl(218, 81%, 95%)"}}>
            FinancePro <br />
            <span style={{color: "hsl(218, 81%, 75%)"}}>O melhor para seu negócio</span>
            </h1>
            <p className="mb-4 opacity-70" style={{color: "hsl(218, 81%, 85%)"}}>
            Sistema financeiro integrado da Droganet. Gerenciamento e controle de boletos,
            consulta de medicamentos e organização financeira para ILPIs.
            </p>
        </div>

        <div className="col-lg-6 mb-5 mb-lg-0 position-relative">
            <div id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong"></div>
            <div id="radius-shape-2" className="position-absolute shadow-5-strong"></div>

            <div className="card bg-glass">
                <div className="card-body px-4 py-5 px-md-5">                
                    <form className="login-form validate-form" onSubmit={handleSubmit}>
            
                        {/* <!-- 2 column grid layout with text inputs for the first and last names --> */}
                        <div className="row mb-4">
                        </div>

                        {/* <!-- Email input --> */}
                        <Form.Group size="lg" controlId="login" className="wrap-input validate-input">
                            <Form.Control
                                className="input"
                                autoFocus
                                type="text"
                                name="email" 
                                autoComplete="off"
                                placeholder="Login ou Email" 
                                // value="1365"
                                onChange={(e) => setLogin(e.target.value)}                            
                            />
                            <span className="focus-input"></span>
                            <span className="symbol-input">
                                <i className="bi bi-envelope-fill" aria-hidden="true"></i>
                            </span>
                        </Form.Group>      



                        <Form.Group size="lg" controlId="senha" className="wrap-input validate-input">                        
                            <Form.Control
                                className="input"
                                type="password"
                                placeholder="Senha"                            
                                onChange={(e) => setSenha(e.target.value)}
                            />
                            <span className="focus-input"></span>
                            <span className="symbol-input">
                                <i className="bi bi-lock-fill" aria-hidden="true"></i>
                            </span>
                        </Form.Group>

                        {/* <!-- Checkbox --> */}
                        <div className="form-check d-flex justify-content-center mb-2">
                            <input className="form-check-input me-2" type="checkbox" value="" id="form2Example33" checked />
                            <label className="form-check-label" for="form2Example33">
                            Manter-me conectado
                            </label>
                        </div>


                        <div className="container-login-form-btn mb-3">
                            <button className="login-form-btn" type="submit" disabled={!validateForm()}>
                                Login
                            </button>
                        </div>


                        {/* <!-- Register buttons --> */}
                        <div className="text-center">
                            <p>Conecte-se através de nossas redes sociais:</p>
                            <button type="button" className="btn btn-link btn-floating mx-1">
                            <i className=""><Facebook /></i>
                            </button>

                            <button type="button" className="btn btn-link btn-floating mx-1">
                            <i className=""><Youtube /></i>
                            </button>

                            <button type="button" className="btn btn-link btn-floating mx-1">
                            <i className=""><Twitter /></i>
                            </button>

                            <button type="button" className="btn btn-link btn-floating mx-1">
                            <i className=""><Instagram /></i>
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        </div>
    </div>
    </section>
    // <!-- Section: Design Block -->







    // <div className="limiter" style={background_}>
    //     <div className="container-login">
    //         <div className="wrap-login" style={{"backgroundColor": estilo.card_color}}>
    //             <div className="tilt-image">
    //                 {/* <img src='/assets/images/img-01.png' alt="IMG"/>  */}
    //                 <img src={`/assets/images/${estilo.logo}`} alt=""/>                      
    //             </div>                

    //             <form className="login-form validate-form" onSubmit={handleSubmit}>
    //                 <span className="login-form-title" style={{"color": estilo.font_color}}>
    //                     Procard Login
    //                 </span>
    //                 <Form.Group size="lg" controlId="login" className="wrap-input validate-input">
    //                     <Form.Control
    //                         className="input"
    //                         autoFocus
    //                         type="text"
    //                         name="email" 
    //                         autoComplete="off"
    //                         placeholder="ProCard ou Email" 
    //                         // value="1365"
    //                         onChange={(e) => setLogin(e.target.value)}                            
    //                     />
    //                     <span className="focus-input"></span>
    //                     <span className="symbol-input">
    //                         <i className="bi bi-envelope-fill" aria-hidden="true"></i>
    //                     </span>
    //                 </Form.Group>                    

    //                 <Form.Group size="lg" controlId="senha" className="wrap-input validate-input">                        
    //                     <Form.Control
    //                         className="input"
    //                         type="password"
    //                         placeholder="Senha"                            
    //                         onChange={(e) => setSenha(e.target.value)}
    //                     />
    //                     <span className="focus-input"></span>
    //                     <span className="symbol-input">
    //                         <i className="bi bi-lock-fill" aria-hidden="true"></i>
    //                     </span>
    //                 </Form.Group>

                    
    //                 <div className="container-login-form-btn">
    //                     <button className="login-form-btn" type="submit" disabled={!validateForm()}>
    //                         Login
    //                     </button>
    //                 </div>

    //                 <div className="text-center mt-2">                           
    //                     {/* <Link to="/resetar_senha">Esqueceu a Senha?</Link> */}                        
    //                     {/* <Link className="txt2" href="/resetar_senha" style={{"color": estilo.font_color}} >
    //                         Esqueceu a Senha?
    //                     </Link> */}
    //                     {/* <Link to="/resetar_senha" className="txt2" style={{"color": estilo.font_color}}>Esqueceu a Senha?</Link> */}

    //                     <NavLink className="txt2" to='/resetar_senha'>Esqueceu a Senha?</NavLink>
              
    //                 </div>                    
    //             </form>
    //         </div>
    //         <div className="prodabit">                        
    //             <a href="http://sistemafrentedecaixa.com.br" target={"blank"} >
    //                 Powered by <b>Prodabit Sistemas</b>
    //             </a>
    //         </div>                    
    //     </div>        
    // </div>    
  );
}

